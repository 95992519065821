import { useState } from "react";
import { BasicButton } from "../../../common/BasicButton/BasicButton.component";
import { Checkbox } from "../../../common/Checkbox/Checkbox.component";
import {
  StyledMainGrid,
  FormWrapper,
  MainWrapper,
  SubHeadline,
  Headline,
  Description,
  SmallText,
  ButtonWrapper,
  CheckboxLabel,
  CheckboxWrapper,
  Anchor,
} from "./ContactForm.styles";
import { StyledInput } from "./ContactForm.styles";
import { useRouter } from "next/router";
import {recaptcha} from "../../../../common/recaptcha";
import { setLocalStorage} from "../../../../common/localstorage";
import {EMAIL_REGEX} from "../../../../common/forms";

export const ContactForm = ({
  keyList, //TODO doublecheck & rm
  contactKey,
  setKeyList,
  headline,
  subHeadline,
  description,
  buttonlabel,
}) => {
  const errorDict = [
    "",
    "Sie haben dieses Feld nicht ausgefüllt.",
    "Das Format der E-Mail-Adresse ist ungültig.",
  ];
  const [name, setName] = useState({ value: "", error: 0 });
  const [surname, setSurname] = useState({ value: "", error: 0 });
  const [company, setCompany] = useState({ value: "", error: 0 });
  const [email, setEmail] = useState({ value: "", error: 0 });
  const router = useRouter();
  const handleSubmit = (e) => {
    var errorFlag = false;
    e.preventDefault();
    if (name.value === "") {
      errorFlag = true;
      setName({ name, error: 1 });
    }
    if (surname.value === "") {
      errorFlag = true;
      setSurname({ ...surname, error: 1 });
    }
    if (email.value === "") {
      errorFlag = true;
      setEmail({ ...email, error: 1 });
    } else {
      if (
        EMAIL_REGEX.test(email.value) ===
        false
      ) {
        errorFlag = true;
        setEmail({ ...email, error: 2 });
      }
    }
    if (errorFlag) {
      e.preventDefault();
      return false;
    } else {
      window.grecaptcha.ready(() => {
        recaptcha(e, (success) => {
          setKeyList((current) => [...current, contactKey]);
          setLocalStorage(contactKey)
          if(success){
            router.replace({
              pathname: router.pathname,
              query: { ...router.query, success: 'true' },
            })
          }
        })
      });
    }
  };
  return (
    <StyledMainGrid>
      <Anchor id="contact" />
      <MainWrapper>
        <SubHeadline>{subHeadline}</SubHeadline>
        <Headline
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
        <Description
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <FormWrapper
          action={
            process.env.NEXT_PUBLIC_SALESFORCE_WEB2LEAD
              ? process.env.NEXT_PUBLIC_SALESFORCE_WEB2LEAD
              : "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          }
          method="POST"
          noValidate
          onSubmit={(e) => handleSubmit(e)}
        >
          
          <input type="hidden" name="salutation" value="diverse" />
          <input type="hidden" name="phone" value="123456789" />
          <input type="hidden" name="00N7Q00000GazwN" value="CaseStudy" /> 
          <input type="hidden" name="00N7Q00000GazwS" value="no message" /> 

          <input type="hidden" name="oid" value="00D7Q00000B4S60" />
          <input
            type="hidden"
            name="lead-quelle"
            id="leadsource"
            value="contactformcasestudy"
          />
          <input
            type="hidden"
            name="recordType"
            id="recordType"
            value="0127Q000000AXiLQAW"
          />
          <StyledInput
            size="small"
            id="first_name"
            error={name.error > 0}
            onChange={(e) => setName({ value: e.target.value, error: 0 })}
            maxlength="40"
            name="first_name"
            type="text"
            helperText={errorDict[name.error]}
            placeholder="Vorname"
            required
          />
          <StyledInput
            size="small"
            id="last_name"
            maxlength="80"
            error={surname.error > 0}
            onChange={(e) => setSurname({ value: e.target.value, error: 0 })}
            helperText={errorDict[surname.error]}
            name="last_name"
            type="text"
            placeholder="Nachname"
            required
          />
          <StyledInput
            size="small"
            id="company"
            maxlength="40"
            name="company"
            error={company.error > 0}
            onChange={(e) => setCompany({ value: e.target.value, error: 0 })}
            helperText={errorDict[company.error]}
            type="text"
            placeholder="Firma"
          />
          <StyledInput
            size="small"
            id="email"
            maxlength="80"
            error={email.error > 0}
            onChange={(e) => setEmail({ value: e.target.value, error: 0 })}
            helperText={errorDict[email.error]}
            name="email"
            type="text"
            placeholder="E-mail"
            required
          />
          <SmallText>
            Die Thalia Digital Retail Solutions benötigt die von Ihnen
            angegebenen Kontaktdaten, um Sie über unsere Produkte und
            Dienstleistungen zu informieren. Sie können Ihre Einwilligung
            jederzeit über{" "}
            <a
              href="mailto:contact-drs@thalia.de"
              target="_blank"
              rel="noreferrer"
            >
              contact-drs@thalia.de
            </a>{" "}
            widerrufen. Informationen zur Abmeldung sowie zu unseren
            Datenschutzpraktiken und unserer Verpflichtung zum Schutz Ihrer
            Daten finden Sie in unserer Datenschutzrichtlinie. Diese Website ist
            durch reCAPTCHA geschützt und es gelten die{" "}
            <a
              href="https://policies.google.com/privacy?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutzbestimmungen
            </a>{" "}
            und
            <a
              href="https://policies.google.com/terms?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Nutzungsbedingungen
            </a>{" "}
            von Google.
          </SmallText>
          <CheckboxWrapper>
            <Checkbox
              type="checkbox"
              id="00N7Q00000Jn6UU"
              name="00N7Q00000Jn6UU"
              value="1"
            />
            <CheckboxLabel>
              Ja, ich möchte außerdem, dass Thalia Digital Retail Solutions mir
              seinen E-Mail-Newsletter mit Informationen, Neuigkeiten und
              Umfragen zusendet. Meine Einwilligung dazu kann ich jederzeit über
              <a
                href="mailto:contact-drs@thalia.com"
                target="_blank"
                rel="noreferrer"
              >
                contact-drs@thalia.com
              </a>{" "}
              widerrufen. Weitere Informationen finde ich hier:
              <a href="/datenschutz" target="_blank" rel="noreferrer">
                Hinweise zum Datenschutz
              </a>
              .
            </CheckboxLabel>
          </CheckboxWrapper>
          <ButtonWrapper>
            <BasicButton label={buttonlabel} type="submit" />
          </ButtonWrapper>
        </FormWrapper>
      </MainWrapper>
    </StyledMainGrid>
  );
};
